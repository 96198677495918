<template>
    <div class="odds-payout" v-show="betslip_count > 0">
        <div class="odds-info" data-toggle="modal" @click="gotoBetSlip" style="cursor: pointer">
            <span class="btn btn-sm slip-text-header text-dark">
                <span class="title">
                    <span class="odd">Total odds: </span> {{ odds | formatOdds }}
                </span>
            </span>
        </div>

        <span class="btn btn-sm slip-text-header text-dark">
            <span class="title">
                <span class="odd">Payout: </span> {{ payout | currency }}
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: "BetslipBottomModalToggle",
    props: {
        betslip_count: {
            type: Number,
            required: true,
        },
        odds: {
            type: Number,
            required: true,
        },
        payout: {
            type: Number,
            required: true,
        },
    },
    methods: {
        hideBetslip: function () {
            document.getElementById("faso-close").click();
        },
        clearAndCloseBetSlip: function () {
            this.hideBetslip();
            this.clearBetSlip();
        },
        gotoBetSlip: function () {
            this.hideBetslip();
            this.$router.push("/bet-slip");
        },
    },
};
</script>

<style scoped>
.odds-info,
.my-bets {
    background: none;
    padding: 0px;
    border-radius: 0.5rem;
    color: var(--darkBackground);
    display: flex;
    align-items: center;
}

.odds-info {
    flex-grow: 1;
    /* outline: 1px solid red; */
}

.odds-info span {
    color: #2b043d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
}

.odd {
    color: #2b043d;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
}

.title {
    flex-grow: 1;
}

.count {
    background: var(--red);
    color: var(--darkBackground);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
}

.odds-payout {
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 3px;
    background-color: var(--yellow);
    color: var(--darkBackground);
    gap: 1rem;
}

.total-odds,
.possible-payout {
    font-size: 14px;
}

.total-odds {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.total-odds>span {
    font-size: 12px;
}
</style>