<template>
  <div class="bottom-fixed-bar" ref="bottomBar">
    <slot></slot>
    <div class="bottom-navigation">
      <a class="nav-item" @click="goTo('sport')" style="color: #a8a6ef; font-weight: 500; cursor: pointer;">
        <img style="width: 25px; height: 25px;" src="/img/icons/bb-icons/home-page.svg"
          class="menu-icon-sports-svg mt-2 ml-2" />
        <span class="item-name mb-2 ml-2">Home</span>
      </a>

      <router-link to="/live" class="nav-item" style="color: #6f6a93;font-weight: 500; cursor: pointer;">
        <div class="icon">
          <img style="width: 25px; height: 25px;" src="/img/icons/bb-icons/live-games.svg"
            class="menu-icon-sports-svg" />
          <span class="custom-badge">
            {{ liveGames }}
          </span>
        </div>

        <span class="item-name mb-0" style="color: #a8a6ef">Live</span>
      </router-link>

      <div v-if="betslip_count > 0" class="major-counter" data-toggle="modal" data-target="#showBetSlip"
        style="display: flex; flex-direction: column; align-items: center; text-align: center;font-weight: 500; cursor: pointer;">
        <div class="icon mb-3" style="position: relative;">
          <span class="betslip-custom-badge">{{ betslip_count }}</span>
        </div>
        <span class="item-name mt-3" style="color: #a8a6ef;margin-left: 30px;">Betslip</span>
      </div>

      <router-link to="/gifts" v-else class="major-counter"
        style="display: flex; flex-direction: column; align-items: center; text-align: center;font-weight: 500; cursor: pointer;">
        <div class="icon mb-3" style="position: relative;">
          <span class="betslip-custom-badge-dark">
            <img class="bounce" style="width: 70px;" src="/img/icons/bb-icons/icons-gift.gif" />
          </span>
        </div>
        <span class="item-name mt-3" style="color: #a8a6ef;margin-left: 30px;">Promos</span>
      </router-link>


      <router-link to="/my-profile-bets" class="nav-item" style="color: #6f6a93;font-weight: 500; cursor: pointer;">
        <div class="icon">
          <img style="width: 22px; height: 22px;" src="/img/icons/bb-icons/bet-history.svg"
            class="menu-icon-sports-svg" />
          <span class="custom-badge">
            {{ normalBetsCount }}
          </span>
        </div>
        <span class="item-name mb-0" style="color: #a8a6ef">My Bets</span>
      </router-link>

      <router-link v-if="isLoggedIn" to="/my-account" class="nav-item"
        style="color: #6f6a93;font-weight: 500; cursor: pointer;">
        <div class="icon">
          <img style="width: 22px; height: 22px;" src="/img/icons/bb-icons/user.svg" class="menu-icon-sports-svg" />
        </div>
        <span class="item-name mb-0 mr-2" style="color: #a8a6ef">Account</span>
      </router-link>

      <router-link v-if="!isLoggedIn" to="/login" class="nav-item"
        style="color: #6f6a93;font-weight: 500; cursor: pointer;">
        <div class="icon">
          <img style="width: 22px; height: 22px;" src="/img/icons/bb-icons/user.svg" class="menu-icon-sports-svg" />
        </div>
        <span class="item-name mb-0 mr-2" style="color: #a8a6ef">Profile</span>
      </router-link>
    </div>
  </div>
</template>

<script>
let observer = null;
export default {
  name: "BottomNavigationMenu",
  props: ["isLoggedIn", "normalBetsCount", "betslip_count", "liveGames"],
  mounted() {
    this.getBottomBarHeight();
    observer = new ResizeObserver(this.getBottomBarHeight).observe(
      this.$refs.bottomBar
    );
  },
  beforeDestroy() {
    console.log("unmounted");
    document.documentElement.style.setProperty("--bottom-bar-height", "0px");
    if (observer) {
      observer.disconnect();
    }
  },
  methods: {
    getBottomBarHeight() {
      const height = this.$refs.bottomBar.clientHeight;
      document.documentElement.style.setProperty(
        "--bottom-bar-height",
        `${height + 20}px`
      );
    },
  },
};
</script>

<style scoped>
.betslip-custom-badge {
  background-color: var(--yellow);
  color: var(--darkBackground);
  border: solid var(--darkBackground) 0.05rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -30px;
  right: -45px;
  z-index: 1;
  margin-top: -5px;
}

.bounce {
  animation: bounceUpDown 3s infinite ease-in-out;
}

.betslip-custom-badge-dark {
  background-color: var(--yellow);
  color: var(--darkBackground);
  border: solid var(--yellow) 0.05rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -30px;
  right: -45px;
  z-index: 1;
  margin-top: -5px;
}

.nav-item .icon {
  position: relative;
}

.bottom-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  max-width: 568px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.bottom-navigation {
  background-color: var(--primary-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--darkerBackground);
}

.bottom-navigation.sticky {
  transform: translateY(0);
}

.custom-badge {
  position: absolute;
  right: -15px;
  bottom: -5px;
  padding: 1px;

  font-size: 13px;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 12px;
  border-radius: 9999px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
  gap: 0.7rem;
}

.nav-item .icon {
  position: relative;
}

.nav-item>i {
  font-size: 20px;
  margin-right: 10px;
}

.nav-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.nav-item>span {
  font-size: 14px;
}
</style>
